import React from 'react';
import { graphql } from 'gatsby';
import TeamMemberBannerSection from '@/components/team-member-banner-section';
import Layout from '@/components/_layout';
import Seo from '@/components/_layout/seo';
import BrandSection from '@/components/brand-section';
import MemberBlogsSection from '@/components/member-blogs-section';
import TeamStorySection from '@/components/team-story-section';
import ConnectWithSection from '@/components/connect-with-section';

const TeamMember = ({ data, location, pageContext }: any): JSX.Element => {
  const {
    // @ts-ignore
    breadcrumb: { crumbs },
  } = pageContext;

  return (
    <Layout location={location} crumbLabel={data?.sanityMembers?.name} crumbs={crumbs}>
      <Seo
        title={
          data?.sanityMembers?.metaInfo?.pageMetaName || data?.sanityBlog?.title || 'Team Member'
        }
        description={data?.sanityMembers?.metaInfo?.pageMetaDescription}
        pageMetadata={data?.sanityMembers?.metaInfo?.metadata}
        canonical={data?.sanityMembers?.metaInfo?.canonical}
      />
      <TeamMemberBannerSection
        title={{ solid: data?.sanityMembers?.name, outlined: data?.sanityMembers?.position }}
        bannerImage={data?.sanityMembers?.bannerPhoto}
      />
      {data?.sanityMembers?.featuredIn?.length > 0 && (
        <BrandSection
          title={{ solid: 'featured in', outlined: '' }}
          brandLogoUrls={data?.sanityMembers?.featuredIn}
        />
      )}
      <TeamStorySection founder={data?.sanityMembers} />
      <ConnectWithSection founder={data?.sanityMembers} />
      <MemberBlogsSection blogs={data?.allSanityBlog?.edges} founder={data?.sanityMembers} />
    </Layout>
  );
};

export default TeamMember;

export const query = graphql`
  query allSanityBlog($id: String!) {
    allSanityBlog(filter: { author: { id: { eq: $id } } }) {
      edges {
        node {
          id
          slug {
            current
          }
          author {
            _id
            description
            name
          }
          excerpt
          thumbnail {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          title
        }
      }
    }

    sanityBrands {
      title {
        title_solid
        title_outlined
        is_outline_first
      }
      brands {
        title
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }

    sanityMembers(id: { eq: $id }) {
      id
      name
      title {
        title_solid
        title_outlined
        is_outline_first
      }
      _rawStory
      metaInfo {
        pageMetaDescription
        pageMetaName
        metadata {
          metaContent
          metaName
        }
        canonical
      }
      social_media {
        youtube
        twitter
        tiktok
        linkedin
        instagram
        facebook
      }
      position
      description
      featuredIn {
        title
        image {
          asset {
            gatsbyImageData(placeholder: DOMINANT_COLOR)
          }
        }
      }
      category
      is_current_member
      photo {
        asset {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      bannerPhoto {
        asset {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      story_image {
        background_image_1 {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        background_image_2 {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        focus_image {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
    sanityTeamsBanner {
      background_image {
        asset {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      addresses {
        address
        address_short_text
        direction
      }
      title
    }
  }
`;
