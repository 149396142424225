import React, { ReactNode } from 'react';
import { Image } from '@/components/_root';
import { motion } from 'framer-motion';
import BlockContent from '@/components/block-content';
interface Props {
  children: ReactNode;
}

interface FlexProps extends Props {
  reverse?: boolean;
}

interface ImageProps {
  imageUrl: { background_image_1: any; background_image_2: any; focus_image: any };
  imageAlt: string;
}

interface TextContentProps {
  isDark?: boolean;
  subHeading?: string;
  description: ReactNode;
}

export const FlexWrapper = ({ children, reverse }: FlexProps): JSX.Element => {
  const flexClass = `flex gap-10 ${reverse ? 'flex-col-reverse' : 'flex-col'} lg:flex-row-reverse`;
  return <div className={flexClass}>{children}</div>;
};

export const ImageWrapper = ({ children }: Props): JSX.Element => {
  return (
    <div className="w-4/5 mx-auto lg:w-1/2 h-7/12 flex justify-center items-center relative">
      {children}
    </div>
  );
};

export const ImageItem = ({ imageUrl, imageAlt }: ImageProps): JSX.Element => (
  <>
    <Image
      src={imageUrl?.focus_image}
      alt={imageAlt}
      className="object-cover h-7/12 rounded-md a md:max-w-md absolute z-30 max-w-xs h-60 md:h-112"
      objectFit="cover"
    />
    <motion.div
      className={`h-7/12 absolute z-20`}
      initial={{ transform: `rotate(0)` }}
      whileInView={{ transform: `rotate(-12deg)` }}
      viewport={{ once: false }}
      transition={{ duration: 0.5 }}
    >
      <Image
        src={imageUrl?.background_image_1}
        alt={imageAlt}
        className="object-cover rounded-md max-w-xs md:max-w-md  h-60 md:h-112"
        objectFit="cover"
      />
    </motion.div>
    <motion.div
      className={`h-7/12 absolute z-20`}
      initial={{ transform: `rotate(0)` }}
      whileInView={{ transform: `rotate(12deg)` }}
      viewport={{ once: false }}
      transition={{ duration: 0.5 }}
    >
      <Image
        src={imageUrl?.background_image_2}
        alt={imageAlt}
        className="object-cover rounded-md max-w-xs md:max-w-md  h-60 md:h-112"
        objectFit="cover"
      />
    </motion.div>
  </>
);

export const ContentWrapper = ({ children, reverse = true }: FlexProps): JSX.Element => {
  const tailwindClass = `mt-16 md:mt-24 lg:mt-0 lg:w-1/2 flex flex-col justify-center lg:justify-start ${
    reverse ? 'mb-12 md:mb-0' : ''
  }`;
  return <p className={tailwindClass}>{children}</p>;
};

export const TextContent = ({ isDark, subHeading, description }: TextContentProps): JSX.Element => {
  return (
    <>
      {subHeading && (
        <p className={`mt-6 font-semibold ${isDark ? 'text-white' : 'text-dominant'}`}>
          {subHeading}
        </p>
      )}
      <div
        className={`mt-6 flex flex-col space-y-6 lg:w-10/12 ${
          isDark ? 'text-white' : 'text-dominant'
        }`}
      >
        <BlockContent blocks={description} />
      </div>
    </>
  );
};
