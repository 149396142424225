import React, { ReactNode } from 'react';
import { Container, PageSection } from '@/components/_root';
import { ContentWrapper, FlexWrapper, ImageItem, ImageWrapper, TextContent } from './styled';
import SectionTitle from '@/components/section-title';

interface Props {
  isDark?: boolean;
  reverse?: boolean;
  title: { outlined: string; solid: string; isOutlinedFirst?: boolean };
  subHeading?: string;
  description: ReactNode;
  imageUrl: { background_image_1: any; background_image_2: any; focus_image: any };
  imageAlt?: string;
}

const SectionSummary = ({
  isDark = false,
  reverse = false,
  title,
  subHeading,
  description,
  imageUrl,
  imageAlt = '',
}: Props): JSX.Element => {
  return (
    <PageSection mode={isDark ? 'dark' : 'light'} paddingAmount="small">
      <Container>
        <FlexWrapper reverse={reverse}>
          <ImageWrapper>
            <ImageItem imageUrl={imageUrl} imageAlt={imageAlt} />
          </ImageWrapper>
          <ContentWrapper>
            <div className="flex flex-col">
              <SectionTitle title={title} mode={isDark ? 'dark' : 'light'} />
            </div>
            <TextContent isDark={isDark} subHeading={subHeading} description={description} />
          </ContentWrapper>
        </FlexWrapper>
      </Container>
    </PageSection>
  );
};

export default SectionSummary;
