import React from 'react';
import { HeaderText, Container, Image } from '@/components/_root';
import { OverlayContainer } from './styled';

interface Props {
  bannerImage: any;
  title: { outlined: string; solid: string; isOutlinedFirst?: boolean };
}

const TeamMemberBannerSection = ({ bannerImage, title }: Props): JSX.Element => {
  return (
    <OverlayContainer>
      <Container className="grid grid-cols-1 md:grid-cols-3 gap-0 mt-32">
        <div className="col-span-2 flex flex-col mb-4">
          <HeaderText
            mode="dark"
            size="h1"
            className={`inline xl:block ${title.isOutlinedFirst && 'order-2'}`}
          >
            {title.solid}
          </HeaderText>
          <HeaderText.Outline
            mode="dark"
            size="h1"
            className="inline xl:block xl:whitespace-nowrap"
          >
            {title.outlined}
          </HeaderText.Outline>
        </div>
        <Image src={bannerImage} alt="" className="mt-auto" />
      </Container>
    </OverlayContainer>
  );
};

export default TeamMemberBannerSection;
