export const whiteLogos = [
  'boohoo-man',
  'club-mad',
  'game-client',
  'house of fraser',
  'Lifestyle sports',
  'odean',
  'parkdean resorts',
  'spa seekers',
];
