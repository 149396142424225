import React from 'react';
import {
	HeaderText,
	PageSection,
	Image,
	Swiper,
	SwiperItem,
} from '@/components/_root';
import { HeaderTextContainer } from './styled';
// import * as styles from './styles.module.css';
import { OverlayContainer } from '@/components/faporbaji/styled';
import { whiteLogos } from '@/utils/helper/white-logos';
import SectionTitle from '@/components/section-title';

interface Props {
	title: { solid: string; outlined: string; isOutlinedFirst?: boolean };
	brandLogoUrls: any[];
}

interface GradientProps extends Props {
	backgroundImgUrl: string;
	backgroundPosition?: string;
}

const BrandSection = ({ title, brandLogoUrls }: Props): JSX.Element => {
	return (
		<PageSection paddingAmount='small'>
			<HeaderTextContainer>
				<SectionTitle title={title} />
			</HeaderTextContainer>
			<Swiper
				autoPlay
				isLoop={brandLogoUrls?.length > 4}
				isCenter={brandLogoUrls?.length < 4}
				pauseOnHover
				spaceBetween={{ sm: 16, md: 20, lg: 24, xl: 28 }}
				showItems={{ sm: 1, md: 2, lg: 3, xl: 4 }}
				preloadImages={false}
				lazy={true}
			>
				{brandLogoUrls.map((brandLogoUrl, index: number) => {
					return (
						<SwiperItem key={index}>
							<div className={`flex h-32 w-auto item-center justify-center`}>
								<Image
									src={brandLogoUrl?.image ?? brandLogoUrl}
									alt=''
									className={`object-contain pointer-events-none ${
										whiteLogos.includes(brandLogoUrl.title) &&
										'filter grayscale invert'
									}`}
									objectFit={'contain'}
								/>
							</div>
						</SwiperItem>
					);
				})}
			</Swiper>
			<noscript>
				<div className='flex grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 sm:gap-16 md:gap-20 lg:gap-24 xl:gap-28 px-10'>
					{brandLogoUrls.slice(0, 4).map((brandLogoUrl, index: number) => {
						return (
							<div className='flex h-32 w-auto justify-center items-center'>
								<img
									src={
										brandLogoUrl?.image?.asset?.gatsbyImageData?.images
											?.fallback?.src
									}
									alt={brandLogoUrl.title}
									className='object-contain w-auto h-full'
								/>
							</div>
						);
					})}
				</div>
			</noscript>
		</PageSection>
	);
};

BrandSection.GradientBackground = ({
	backgroundImgUrl,
	backgroundPosition = 'cover',
	title,
	brandLogoUrls,
}: GradientProps): JSX.Element => {
	return (
		<OverlayContainer
			backgroundImgUrl={backgroundImgUrl}
			backgroundPosition={backgroundPosition}
		>
			<PageSection paddingAmount='small'>
				<HeaderTextContainer>
					<HeaderText size='h2' mode='dark'>
						{title.solid}
					</HeaderText>
				</HeaderTextContainer>
				<Swiper
					isLoop
					autoPlay
					showBleeding
					showItems={{ sm: 1, md: 3, lg: 4, xl: 5 }}
				>
					{brandLogoUrls.map((brandLogoUrl, index: number) => (
						<SwiperItem key={index}>
							<div className={`flex h-32 w-auto item-center justify-center`}>
								<Image
									src={brandLogoUrl?.image ?? brandLogoUrl}
									alt=''
									className=' object-contain pointer-events-none filter grayscale brightness-0 invert mr-8'
									objectFit={'contain'}
								/>
							</div>
						</SwiperItem>
					))}
				</Swiper>
			</PageSection>
		</OverlayContainer>
	);
};

export default BrandSection;
