import React from 'react';
import {
  FacebookIcon,
  TwitterIcon,
  InstagramIcon,
  LinkedinIcon,
  YoutubeIcon,
  TiktokIcon,
} from '@/components/_icons';
import { HeaderText, PageSection } from '@/components/_root';
import { OverlayContainer, SocialMediaContainer } from './styled';
import useWindowDimensions from '@/utils/hooks';

interface Props {
  founder: any;
}

const ConnectWithSection = ({ founder }: Props): JSX.Element => {
  const { width } = useWindowDimensions();

  const size = width > 768 ? 48 : 31;

  const socialLinks = [
    { icon: <FacebookIcon size={size} />, link: founder?.social_media?.facebook },
    { icon: <TwitterIcon size={size} />, link: founder?.social_media?.twitter },
    { icon: <InstagramIcon size={size} />, link: founder?.social_media?.instagram },
    { icon: <LinkedinIcon size={size} />, link: founder?.social_media?.linkedin },
    { icon: <YoutubeIcon size={size} />, link: founder?.social_media?.youtube },
    { icon: <TiktokIcon size={size} />, link: founder?.social_media?.tiktok },
  ];

  return (
    <OverlayContainer>
      <PageSection>
        <div className="flex justify-center flex-col md:flex-row items-center">
          <HeaderText.Outline mode="dark" size="h2">
            CONNECT WITH&nbsp;
          </HeaderText.Outline>
          <HeaderText mode="dark" size="h2">
            {founder?.name}
          </HeaderText>
        </div>
        <SocialMediaContainer>
          {socialLinks.map((socialLink, index) => {
            if (!socialLink.link) return null;
            return (
              <a href={socialLink.link} key={index}>
                {socialLink.icon}
              </a>
            );
          })}
        </SocialMediaContainer>
      </PageSection>
    </OverlayContainer>
  );
};

export default ConnectWithSection;
