import React from 'react';
import SectionSummary from '../section-summary';
import { getTitle } from '@/utils/helper/getTitle';
interface Props {
  founder: any;
}

const TeamStorySection = ({ founder }: Props): JSX.Element => (
  <SectionSummary
    isDark
    imageUrl={founder?.story_image}
    title={getTitle(founder)}
    description={founder?._rawStory}
    reverse
  />
);

export default TeamStorySection;
