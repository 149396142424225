import { IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import { Card, Container, HeaderText, PageSection, Swiper, SwiperItem } from '@/components/_root';

interface IBlog {
  love_count: string;
  like_count: string;
  clap_count: string;
  blog: {
    title: string;
    slug: { current: string };
    excerpt: string;
    thumbnail: IGatsbyImageData;
  };
}

interface Props {
  blogs: IBlog[];
  founder: any;
}

const MemberBlogsSection = ({ blogs, founder }: Props): JSX.Element => {
  return (
    <PageSection>
      <Container>
        <HeaderText.Outline size="h2" mode="light" className="inline-block">
          {founder?.name?.split(' ')[0]}&nbsp;
        </HeaderText.Outline>
        <HeaderText size="h2" mode="light" className="md:inline-block mb-12">
          LATEST BLOGS
        </HeaderText>
      </Container>
      <Swiper showBleeding haveOffset>
        {blogs?.map(({ node: blog }: any, index: number) => (
          <SwiperItem key={index}>
            <Card.Blog
              id={blog.id}
              slug={blog.slug}
              cardTitle={blog.title}
              description={blog.excerpt}
              thumbnail={blog.thumbnail}
              reactionStats={{
                love: '10K',
                celebrate: '21K',
                like: '12K',
              }}
            />
          </SwiperItem>
        ))}
      </Swiper>
    </PageSection>
  );
};

export default MemberBlogsSection;
